var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0"},[_c('tr',[_c('td',{staticStyle:{"width":"80px !important","padding-right":"4px !important"}},[_c('div',[_c('v-checkbox',{staticClass:"mb-5",attrs:{"disabled":_vm.disabledCheck,"primary":"","hide-details":"","value":_vm.item.empleado_id},on:{"click":function($event){return _vm.selectEmployee(_vm.item.empleado_id)}},model:{value:(_vm.seleccionado),callback:function ($$v) {_vm.seleccionado=$$v},expression:"seleccionado"}})],1)]),_c('td',{staticStyle:{"padding-left":"16px !important","min-width":"300px!important"}},[_c('div',[_c('v-list',{staticClass:"py-0 my-0",staticStyle:{"background":"transparent"}},[_c('v-list-item',{staticClass:"py-0 my-0 px-0"},[_c('v-list-item-avatar',{staticClass:"py-0 my-0"},[_c('v-img',{staticClass:"py-0 my-0",attrs:{"src":_vm.urlImg+_vm.item.foto}})],1),_c('v-list-item-content',{staticClass:"ml-0 py-0 my-0"},[_c('v-list-item-title',{staticClass:"tblPrincipal text-wrap"},[_vm._v(_vm._s(_vm.item.nombre_completo))]),_c('v-list-item-title',{staticClass:"tblSecundario text-mute"},[_vm._v(_vm._s(/* rol=="root"?item.empleado.cliente.nombre:rol=="admin"?item.empleado.empresa.nombre:item.empleado.sucursal.nombre*/))])],1)],1)],1)],1)]),_c('td',{staticStyle:{"min-width":"300px!important"}},[(_vm.item.contratos[0])?_c('div',[_vm._v(" "+_vm._s(_vm.item.contratos[0].nombre)+" ")]):_vm._e()]),_c('td',{staticStyle:{"min-width":"300px!important"}},[(_vm.item.contratos[0])?_c('div',[_vm._v(" "+_vm._s(_vm.item.contratos[0].vigencia?_vm.item.contratos[0].vigencia:'Indeterminada')+" ")]):_vm._e()]),_c('td',{staticStyle:{"min-width":"300px!important"}},[(_vm.item.contratos[0])?_c('div',[(_vm.item.contratos[0].clave_estatus == 'listo')?_c('p',{staticClass:"estatus-listo mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.contratos[0].clave_estatus == 'incompleto')?_c('p',_vm._g({staticClass:"estatus-incompleto mb-0 text-center"},on),[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e()]}}],null,false,4172239751)},[_c('span',[_vm._v(_vm._s(_vm.item.contratos[0].observaciones))])]),(_vm.item.contratos[0].clave_estatus == 'firmado')?_c('p',{staticClass:"estatus-firmado mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e(),(_vm.item.contratos[0].clave_estatus == 'vencido')?_c('p',{staticClass:"estatus-vencido mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e(),(_vm.item.contratos[0].clave_estatus == 'cancelado')?_c('p',{staticClass:"estatus-cancelado mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e(),(_vm.item.contratos[0].clave_estatus == 'por_vencer')?_c('p',{staticClass:"estatus-por_vencer mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e(),(_vm.item.contratos[0].clave_estatus == 'renovado')?_c('p',{staticClass:"estatus-renovado mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e()],1):_vm._e()]),_c('td',{staticStyle:{"padding-left":"8px","padding-right":"8px"}},[(_vm.item.contratos[0])?_c('div',{staticClass:"tblOpciones"},[(_vm.tipoDoc(_vm.item.contratos[0].url_archivo))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.contratos[0].previsualizar)?_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModalPDF(_vm.item.contratos[0])}}},on),[_c('v-icon',{staticClass:"iconoDelgadoBoton",domProps:{"textContent":_vm._s('$visibility_outline')}})],1):_vm._e()]}}],null,false,2343198344)},[_c('span',[_vm._v("Vista previa")])])]:_vm._e(),_c('v-menu',{staticClass:"elevation-0 d-inline",attrs:{"buttom":"","left":"","content-class":"mt-0 menuClassAcciones"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0",attrs:{"icon":"","fab":"","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._l((attrs),function(item,valor){return [(valor == 'aria-expanded' &&  item == 'false')?_c('v-icon',{key:valor,staticClass:"pl-2",attrs:{"color":"#828282"}},[_vm._v("more_vert")]):_vm._e(),(valor == 'aria-expanded' &&  item == 'true')?_c('v-icon',{key:valor,staticClass:"pl-2",attrs:{"color":"#2DB9D1"}},[_vm._v("more_vert")]):_vm._e()]})],2)]}}],null,false,157656946)},[_c('v-list',[(_vm.item.baja)?[(_vm.item.contratos[0].clave_estatus != 'incompleto')?_c('v-list-item',{on:{"click":function($event){return _vm.descargar(_vm.item.contratos[0], _vm.item.nombre_completo)}}},[_c('v-list-item-title',[_vm._v("Descargar contrato")])],1):_vm._e()]:[(
                                    _vm.item.contratos[0].clave_estatus == 'listo' ||
                                    _vm.item.contratos[0].clave_estatus == 'firmado' ||
                                    _vm.item.contratos[0].clave_estatus == 'vencido' ||
                                    _vm.item.contratos[0].clave_estatus == 'por_vencer'
                                )?_c('v-list-item',{on:{"click":function($event){return _vm.abrirModalRenovar(_vm.item.contratos[0])}}},[_c('v-list-item-title',[_vm._v("Renovar contrato")])],1):_vm._e(),(
                                    _vm.item.contratos[0].clave_estatus == 'listo' ||
                                    _vm.item.contratos[0].clave_estatus == 'incompleto' ||
                                    _vm.item.contratos[0].clave_estatus == 'firmado' ||
                                    _vm.item.contratos[0].clave_estatus == 'vencido' ||
                                    _vm.item.contratos[0].clave_estatus == 'por_vencer'
                                )?_c('v-list-item',{on:{"click":function($event){return _vm.regenerarContrato(_vm.item.contratos[0])}}},[_c('v-list-item-title',[_vm._v("Volver a generar")])],1):_vm._e(),(
                                    _vm.item.contratos[0].clave_estatus == 'listo' ||
                                    _vm.item.contratos[0].clave_estatus == 'firmado' ||
                                    _vm.item.contratos[0].clave_estatus == 'vencido' ||
                                    _vm.item.contratos[0].clave_estatus == 'cancelado' ||
                                    _vm.item.contratos[0].clave_estatus == 'por_vencer' ||
                                    _vm.item.contratos[0].clave_estatus == 'renovado'
                                )?_c('v-list-item',{on:{"click":function($event){return _vm.descargar(_vm.item.contratos[0], _vm.item.nombre_completo)}}},[_c('v-list-item-title',[_vm._v("Descargar contrato")])],1):_vm._e(),(
                                    _vm.item.contratos[0].clave_estatus == 'listo' ||
                                    _vm.item.contratos[0].clave_estatus == 'firmado' ||
                                    _vm.item.contratos[0].clave_estatus == 'vencido' ||
                                    _vm.item.contratos[0].clave_estatus == 'por_vencer' ||
                                    (_vm.item.contratos[0].clave_estatus == 'renovado' && _vm.item.contratos[0].url_archivo == null)
                                )?_c('v-list-item',{on:{"click":function($event){return _vm.abrirModalCargaDocumento(_vm.item,_vm.item.contratos[0].id)}}},[(_vm.item.contratos[0].clave_estatus == 'firmado')?_c('v-list-item-title',[_vm._v("Actualizar contrato firmado")]):_c('v-list-item-title',[_vm._v("Subir contrato firmado")])],1):_vm._e(),(_vm.item.contratos[0].clave_estatus == 'cancelado' )?_c('v-list-item',{on:{"click":function($event){return _vm.eliminar(_vm.item.contratos[0].id)}}},[_c('v-list-item-title',[_vm._v("Eliminar contrato")])],1):_vm._e(),(_vm.item.contratos[0].url_archivo)?_c('v-list-item',{on:{"click":function($event){return _vm.eliminarDocumento(_vm.item.contratos[0].id, _vm.item.contratos[0].url_archivo)}}},[_c('v-list-item-title',[_vm._v("Eliminar documento contrato")])],1):_vm._e()]],2)],1)],2):_vm._e()]),_c('td',{staticStyle:{"min-width":"70px!important","max-width":"81px!important","width":"80px","padding-left":"8px"}},[(_vm.contratos.length > 0)?_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){_vm.isOpen ? _vm.isOpen = false:_vm.isOpen = true}}},on),[_c('v-icon',{staticClass:"tamIconoBoton iconoDelgadoBoton"},[_vm._v(_vm._s(_vm.isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'))])],1)]}}],null,false,801905789)},[_c('span',[_vm._v("Desplegar")])])],1):_vm._e()])]),(_vm.isOpen)?_vm._l((_vm.contratos),function(contrato){return _c('tr',{key:contrato.id,staticClass:"td-short"},[_c('td',{staticStyle:{"width":"70px !important","padding-left":"40px","padding-right":"40px"}}),_c('td'),_c('td',[_c('div',[_vm._v(" "+_vm._s(contrato.nombre)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(contrato.vigencia?contrato.vigencia:'Indeterminada')+" ")])]),_c('td',[_c('div',[(contrato.clave_estatus == 'listo')?_c('p',{staticClass:"estatus-listo mb-0 text-center"},[_vm._v(_vm._s(contrato.estatus))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [(contrato.clave_estatus == 'incompleto')?_c('p',_vm._g({staticClass:"estatus-incompleto mb-0 text-center"},on),[_vm._v(_vm._s(contrato.estatus))]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(contrato.observaciones))])]),(contrato.clave_estatus == 'firmado')?_c('p',{staticClass:"estatus-firmado mb-0 text-center"},[_vm._v(_vm._s(contrato.estatus))]):_vm._e(),(contrato.clave_estatus == 'vencido')?_c('p',{staticClass:"estatus-vencido mb-0 text-center"},[_vm._v(_vm._s(contrato.estatus))]):_vm._e(),(contrato.clave_estatus == 'cancelado')?_c('p',{staticClass:"estatus-cancelado mb-0 text-center"},[_vm._v(_vm._s(contrato.estatus))]):_vm._e(),(contrato.clave_estatus == 'por_vencer')?_c('p',{staticClass:"estatus-por_vencer mb-0 text-center"},[_vm._v(_vm._s(contrato.estatus))]):_vm._e(),(contrato.clave_estatus == 'renovado')?_c('p',{staticClass:"estatus-renovado mb-0 text-center"},[_vm._v(_vm._s(contrato.estatus))]):_vm._e()],1)]),_c('td',{staticStyle:{"padding-left":"8px","padding-right":"8px"}},[_c('div',{staticClass:"tblOpciones"},[(_vm.tipoDoc(contrato.url_archivo))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [(contrato.previsualizar)?_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModalPDF(contrato)}}},on),[_c('v-icon',{staticClass:"iconoDelgadoBoton",domProps:{"textContent":_vm._s('$visibility_outline')}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Vista previa")])])]:_vm._e(),_c('v-menu',{staticClass:"elevation-0 d-inline",attrs:{"buttom":"","left":"","content-class":"mt-0 menuClassAcciones"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0",attrs:{"icon":"","fab":"","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._l((attrs),function(item,valor){return [(valor == 'aria-expanded' &&  item == 'false')?_c('v-icon',{key:valor,staticClass:"pl-2",attrs:{"color":"#828282"}},[_vm._v("more_vert")]):_vm._e(),(valor == 'aria-expanded' &&  item == 'true')?_c('v-icon',{key:valor,staticClass:"pl-2",attrs:{"color":"#2DB9D1"}},[_vm._v("more_vert")]):_vm._e()]})],2)]}}],null,true)},[_c('v-list',[(_vm.item.baja)?[(contrato.clave_estatus  != 'incompleto')?_c('v-list-item',{on:{"click":function($event){return _vm.descargar(contrato, _vm.item.nombre_completo)}}},[_c('v-list-item-title',[_vm._v("Descargar contrato")])],1):_vm._e()]:[(
                                        contrato.clave_estatus == 'listo' ||
                                        contrato.clave_estatus == 'incompleto' ||
                                        contrato.clave_estatus == 'firmado' ||
                                        contrato.clave_estatus == 'vencido' ||
                                        contrato.clave_estatus == 'por_vencer'
                                    )?_c('v-list-item',{on:{"click":function($event){return _vm.regenerarContrato(contrato)}}},[_c('v-list-item-title',[_vm._v("Volver a generar")])],1):_vm._e(),(
                                        contrato.clave_estatus == 'listo' ||
                                        contrato.clave_estatus == 'firmado' ||
                                        contrato.clave_estatus == 'vencido' ||
                                        contrato.clave_estatus == 'cancelado' ||
                                        contrato.clave_estatus == 'por_vencer' ||
                                        contrato.clave_estatus == 'renovado'
                                    )?_c('v-list-item',{on:{"click":function($event){return _vm.descargar(contrato, _vm.item.nombre_completo)}}},[_c('v-list-item-title',[_vm._v("Descargar contrato")])],1):_vm._e(),(
                                        contrato.clave_estatus == 'listo' ||
                                        contrato.clave_estatus == 'firmado' ||
                                        contrato.clave_estatus == 'vencido' ||
                                        contrato.clave_estatus == 'por_vencer' ||
                                        (contrato.clave_estatus == 'renovado' && contrato.url_archivo == null)
                                    )?_c('v-list-item',{on:{"click":function($event){return _vm.abrirModalCargaDocumento(_vm.item,contrato.id)}}},[(contrato.clave_estatus == 'firmado')?_c('v-list-item-title',[_vm._v("Actualizar contrato firmado")]):_c('v-list-item-title',[_vm._v("Subir contrato firmado")])],1):_vm._e(),(contrato.clave_estatus == 'cancelado')?_c('v-list-item',{on:{"click":function($event){return _vm.eliminar(contrato.id)}}},[_c('v-list-item-title',[_vm._v("Eliminar contrato")])],1):_vm._e(),(contrato.url_archivo)?_c('v-list-item',{on:{"click":function($event){return _vm.eliminarDocumento(contrato.id, contrato.url_archivo)}}},[_c('v-list-item-title',[_vm._v("Eliminar documento contrato")])],1):_vm._e()]],2)],1)],2)]),_c('td',{staticStyle:{"min-width":"70px!important","max-width":"81px!important","width":"80px","padding-left":"8px"}})])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }